@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

/* comman css start */

iframe[style*="position: fixed;"] {
  display: none !important;
}

.h-35 {
  height: 35px !important;
}
.w-77 {
  width: 77px !important ;
}
.red {
  color: rgb(132, 32, 41) !important;
}

.jc_sb {
  justify-content: space-between !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.m_reset {
  margin: 0 auto 15px;
}

.green {
  color: rgb(15, 81, 50) !important;
}

.user_data_search .react-datepicker-wrapper,
.user_data_search .react-datepicker__input-container,
.user_data_search .react-datepicker__input-container input {
  height: 100% !important;
}

.mw-un {
  max-width: 2000px !important;
}

.w-fc {
  width: fit-content !important;
}

.m-0 {
  margin: 0 !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-0 {
  margin-left: 0 !important;
}

.mt-8 {
  margin-top: 8px !important;
}
.ta_c {
  text-align: center !important;
}
.c-grey {
  color: #59667a !important;
}

.contact_modal_time {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.span_3 {
  grid-column: span 3 / span 3 !important;
}
.span_2 {
  grid-column: span 2 / span 2 !important;
}

/* .pd_10 {
  padding: 10px !important;
} */

.fs-19 {
  font-size: 19px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.w-230 {
  width: 230px !important;
}
.w-460 {
  width: 460px !important;
}
.block {
  display: block !important;
}

.margin-left {
  margin-left: 10px;
}

.align-items-start {
  align-items: flex-start !important;
}

body,
html {
  overflow-x: hidden;
}

body {
  background: #f4f5f7;
  min-height: 100vh;
  height: auto;
  font-family: "Roboto", sans-serif !important;
}

input[type="checkbox" i] {
  accent-color: #59667a;
}

textarea {
  resize: vertical;
}

.ml-auto {
  margin-left: auto;
}

textarea,
button {
  font-family: "Roboto", sans-serif !important;
}

.oc-btn-loader {
  display: inline-block;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
  border: 2.5px solid #3f4358;
  border-right-color: transparent;
  width: 15px;
  height: 15px;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.Layout {
  display: flex;
}

.colorpick-eyedropper-input-trigger {
  display: none !important;
}

.h-35 {
  height: 35px !important;
}

.react-datepicker-popper {
  z-index: 999999;
}

.width_max_content {
  width: max-content !important;
}

#sidebar_toggel {
  display: none;
}

.inline_block {
  display: inline-block !important;
}

.service_view_section {
  margin-top: -58px;
}

.project_common_form_input_check_box_row {
  margin: 0 -10px;
  margin-top: 13px;
}

.disabled_input {
  color: #717171 !important;
  cursor: not-allowed;
}

.disabled_input input[type="radio"],
.disabled_input label.create_from_radio_label {
  cursor: not-allowed;
  color: #ababab;
  pointer-events: none; /* This ensures the buttons are not clickable */
}

.disabled_input label.project_common_form_check_box[for="PENDING1"] {
  color: #ababab;
}
.disabled_input label.create_from_radio_label[for="IN_PROGRESS2"] {
  color: #ababab;
}

/* Apply styles to the disabled radio buttons directly */
input[type="radio"]:disabled + label.create_from_radio_label {
  cursor: not-allowed;
  color: #ababab;
}

.visitor_disabled_input {
  color: #ababab !important;
  cursor: not-allowed;
  border: 1px solid #eeeeee !important;
}

.country_code_disable_input {
  color: #ababab !important;
  cursor: not-allowed;
  border: 1px solid #eeeeee !important;
  pointer-events: none;
}
.required::after {
  content: " *";
  color: red;
  padding-left: 2px;
}

.main_content {
  max-width: calc(100% - 280px);
  width: 100%;
  transition: all 0.5s ease-in-out;
  margin-left: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  z-index: 99;
}

#sidebar_toggel:checked ~ .main_content {
  max-width: calc(100% - 90px);
}

.loader {
  min-height: 50vh;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-roller {
  /* display: inline-block; */
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000000;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.select__single-value,
.main_content .login_input_row input {
  color: #717171 !important;
}

.comman_btn {
  font-size: 14px;
  padding: 6px 28px;
  font-weight: 600;
  background: #3f4358;
  border: 1px solid #3f4358;
  color: #dddbdb;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.35s ease-in-out, background-color 0.35s ease-in-out,
    border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out;
  display: inline-block;
}
.comman_btn.btn-toggle:hover {
  background: #3f4358 !important;
  border: 1px solid #3f4358 !important;
  color: #dddbdb !important;
}
.comman_btn .oc-btn-loader {
  border-color: #dddbdb;
  border-right-color: transparent;
  transition: color 0.35s ease-in-out, background-color 0.35s ease-in-out,
    border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out;
}

.comman_btn:disabled {
  opacity: 0.5;
  /* pointer-events: none; */
  cursor: not-allowed !important;
}
.comman_btn:hover .oc-btn-loader {
  border-color: #3f4358;
  border-right-color: transparent;
}
.inactive_btn {
  background-color: #dddbdb !important;
  border-color: #dddbdb !important;
  color: #1e1e1e !important;
}

.oc_mobile_version_cont {
  display: flex;
  justify-content: space-between;
}

.inactive_btn:hover {
  opacity: 0.8;
}

.basic-multi-select {
  z-index: 999 !important;
}

.create_from_row .mobile_config_radio .create_from_radio_checkbox {
  display: none;
}

.zu_privacy_terms_banner_img_text {
  height: 350px;
  overflow: hidden;
  position: relative;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}
.zu_all_page_main_content .zu_privacy_terms_banner_img_text {
  margin-top: -30px;
  margin-left: -40px;
  margin-right: -40px;
}
.zu_privacy_terms_banner_img_text .zu_privacy_terms_banner_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.zu_privacy_terms_banner_img_text::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00000073;
}
.zu_privacy_terms_banner_img_text .zu_privacy_terms_banner_text {
  position: relative;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 15px;
  text-align: center;
}
.zu_privacy_terms_banner_img_text .zu_privacy_terms_banner_heading {
  font-size: 50px;
  color: #ffffff;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 10px;
}
.zu_privacy_terms_banner_img_text .zu_privacy_terms_banner_paragraph {
  font-size: 22px;
  color: #ffffff;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 15px;
}
.zu_privacy_terms_banner_img_text .zu_privacy_terms_banner_updat_date {
  font-size: 16px;
  color: #ffffff;
  font-family: "Proxima-Nova-Regular";
}

.zu_privacy_terms_section {
  padding-bottom: 30px;
}
.zu_privacy_terms_section .container {
  display: block;
  min-height: auto;
  width: 90%;
}

.zu_all_page_main_content .zu_privacy_terms_section .container {
  padding: 0;
  max-width: 100%;
}

.zu_privacy_terms_content {
  border: 1px solid #e2e2e2;
  padding: 24px;
  border-radius: 5px;
}

.zu_privacy_terms_btn {
  color: #ffffff;
  text-decoration: none;
  border: 2px solid #ffffff;
  padding: 10px 20px;
  display: block;
  width: fit-content;
  text-align: center;
  font-size: 18px;
  font-family: "Proxima-Nova-Bold";
  position: relative;
  transition: all 0.3s ease-in-out;
  z-index: 9;
}
.zu_privacy_terms_btn:hover {
  color: #000000;
}
.zu_privacy_terms_btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: #ffffff;
  transition: all 0.3s ease-in-out;
  z-index: -1;
}
.zu_privacy_terms_btn:hover::before {
  width: 100%;
}

.zu_privacy_terms_common_heading {
  font-size: 20px;
  font-family: "Proxima-Nova-Bold";
  margin-bottom: 0px;
  color: #5f0166;
}
.zu_privacy_terms_common_paragraph {
  font-size: 16px;
  font-family: "Proxima-Nova-Regular";
  margin-bottom: 0px;
  color: #757575;
}
.zu_privacy_terms_common_paragraph a {
  color: #5f0166;
  font-family: "Proxima-Nova-Bold";
}
.zu_privacy_terms_common_paragraph .zu_privacy_terms_common_paragraph_bold {
  font-family: "Proxima-Nova-Bold";
}

.visitor_label_tenant {
  z-index: 9999 !important;
}

.general_form_tanant {
  z-index: 999 !important;
}

.zu_privacy_terms_common_list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.zu_privacy_terms_common_list li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 30px;
}
.zu_privacy_terms_common_list li::before {
  content: "";
  position: absolute;
  left: 5px;
  top: 12px;
  width: 12px;
  height: 2px;
  border-radius: 10px;
  background: #5f0166;
}
.zu_privacy_terms_common_list li:last-child {
  margin-bottom: 0px;
}

.zu_privacy_terms_covid_content {
  background: #d64657;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 25px;
}
.zu_privacy_terms_covid_content .zu_privacy_terms_covid_heading {
  font-size: 30px;
  font-family: "Proxima-Nova-Bold";
  color: #edf000;
  margin-bottom: 20px;
}
.zu_privacy_terms_covid_content .zu_privacy_terms_common_heading,
.zu_privacy_terms_covid_content .zu_privacy_terms_common_paragraph {
  color: #ffffff;
}
.zu_privacy_terms_covid_content .zu_privacy_terms_common_list {
  counter-reset: my-awesome-counter;
}
.zu_privacy_terms_covid_content .zu_privacy_terms_common_list li {
  counter-increment: my-awesome-counter;
}
.zu_privacy_terms_covid_content .zu_privacy_terms_common_list li::before {
  content: counter(my-awesome-counter) ". ";
  color: #ffffff;
  background: transparent;
  width: auto;
  height: auto;
  left: 10px;
  top: 4px;
  line-height: 1;
  font-family: "Proxima-Nova-Bold";
}
.zu_privacy_terms_covid_content .zu_privacy_terms_btn {
  margin: 25px auto 0;
}

.zu_privacy_terms_condition_pdf_content,
.zu_privacy_second_terms_condition_pdf_content {
  background: #00bf787a;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 25px;
}
.zu_privacy_terms_condition_pdf_content .zu_privacy_terms_common_heading,
.zu_privacy_second_terms_condition_pdf_content
  .zu_privacy_terms_common_heading {
  font-size: 30px;
  font-family: "Proxima-Nova-Bold";
  text-align: center;
  color: #ffffff;
}
.zu_privacy_terms_condition_pdf_content .zu_privacy_terms_btn,
.zu_privacy_second_terms_condition_pdf_content .zu_privacy_terms_btn {
  margin: 25px auto 0;
}

.zu_privacy_second_terms_condition_pdf_content {
  background: #000000;
  margin-top: 25px;
}

/* .red_btn {
  background: red;
  border: none;
}

.red_btn:hover {
  background-color: rgb(104, 7, 7) !important;
  color: #dddbdb !important;
} */

.secondary_btn {
  background-color: #bdbaba !important;
  color: #3f4358 !important;
  border: none;
}

.secondary_btn:hover {
  background-color: #616164 !important;
  color: #dddbdb !important;
}
.setting_btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px !important;
}

.pending_status_color {
  color: #000000 !important;
}
.service_request_back {
  padding: 5px;
}
.inprogress_status_color {
  color: rgb(24, 4, 116) !important;
  background-color: rgba(99, 65, 250, 0.3) !important;
}
.completed_status_color {
  color: rgb(105, 61, 4) !important;
  background-color: rgba(226, 176, 111, 0.3) !important;
}

.featured_btn {
  padding: 2px 10px;
}

.featured_btn:hover::after {
  content: " Add to Featured";
}
.unfeatured_btn {
  padding: 2px 10px;
}

.unfeatured_btn:hover::after {
  content: " Remove from Featured";
}

.featured_btn_width {
  width: 30%;
}

.save_featured_cont {
  display: block;
}

.save_featured {
  margin-top: 1rem;
}

.legal_doc_button {
  padding: 20px 0 0;
}

.basic-multi-select {
  z-index: 9999;
}

.basic-multi-select .select__control {
  display: flex !important;
  padding: 0 !important;
}

.basic-multi-select .select__control .select__indicators {
  margin: -6px 0 -6px 0;
}

.basic-multi-select .select__control .select__indicators .select__indicator {
  padding: 6px;
}

.basic-multi-select .select__control {
  display: flex;
  align-items: center;
  box-shadow: none;
  min-height: auto;
}

.error_filed .create_from_input,
.error_filed .basic-multi-select .select__control,
.error_filed
  .basic-multi-select
  .select__control
  .select__indicators
  .select__indicator,
.error_filed .create_from_input_file {
  color: #f00;
  border-color: #f00;
}

/* .comman_btn:last-child {
  margin-left: 2rem;
} */

.plan_list_id {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.create_from_bottom_btn {
  gap: 16px;
  display: flex;
  justify-content: flex-start;
}

.send_notification-btn {
  max-width: 1100px;
  /* margin: 0 auto; */
}

.notification_btn_container {
  display: flex;
  /* justify-content: flex-end; */
  max-width: 1100px;
  margin: auto;
}

.feedback_status-btn {
  justify-content: center;
}

.comman_btn:hover {
  background-color: #dddbdb;
  border-color: #dddbdb;
  color: #1e1e1e;
}

.project_submit_bottom_btn {
  margin-top: 16px;
}

.project_submit_bottom_btn.center_back_btn {
  padding: 20px 0 0;
  max-width: 1100px;
  margin: 0 auto;
  gap: 15px;
  display: flex;
  flex-wrap: wrap;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.col_span_2 {
  grid-column: span 2 / span 2;
}
.col_span_3 {
  grid-column: span 3 / span 3;
}
.error_modal_msg {
  color: #842029;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  border-radius: 4px;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  display: block;
  position: fixed;
  right: 40px;
  top: 40px;
  z-index: 999;
}

.col_lg_3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.col_lg_4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.d_grid {
  display: grid;
}

.gap_15 {
  gap: 15px;
}

.footer_section {
  margin-top: auto;
  background: #ffffff;
  padding: 15px 40px;
  display: flex;
  align-items: center;
  box-shadow: 0px -15px 30px 0px rgb(0 0 0 / 2%);
  border-top: 2px solid #dddbdb;
}

.sidemenu_btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
}

.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; If you want dots under the hoverable text */
}

.tooltip .tooltiptext {
  visibility: hidden;
  /* width: 120px; */
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 3px;
  left: 50%;
  bottom: 26px;
  position: absolute;
  z-index: 1;
  transform: translateX(-50%);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* comman css end */

/* sidebar css start */

.sidebar_container {
  box-shadow: 0px 15px 30px 0px rgb(0 0 0 / 2%);
  min-height: 100vh;
  height: auto;
  max-width: 280px;
  width: 100%;
  background: #fff;
  padding: 15px 20px;
  transition: all 0.5s ease-in-out;
  border-right: 1px solid #0000001f;
  position: fixed;
  /* z-index: 999; */
}

.sidebar_icon_img {
  background-color: #3f4358;
  color: #3f4358;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  margin-right: 16px;
  transition: all 0.5s ease-in-out;
}

.sidebar_container
  .sidebar_nav
  .sidebar_item
  .sidebar_link:hover
  .sidebar_icon_img {
  background-color: #000;
  color: #000;
}

.sidebar_container
  .sidebar_nav
  .sidebar_item
  .sidebar_link:hover
  .sidebar_icon_img,
.sidebar_container
  .sidebar_nav
  .sidebar_item
  .sidebar_link.active
  .sidebar_icon_img {
  background: #1e1e1e;
  color: #ffffff;
}

.sidebar_container .sidebar_logo {
  display: block;
}

.sidebar_container .sidebar_logo img {
  max-width: 130px;
  height: 80px;
  width: 100%;
  display: block;
  margin: 0 auto;
  object-fit: contain;
}

.sidebar_container .sidebar_nav {
  list-style: none;
  margin: 30px -20px -15px;
  max-height: calc(100vh - 81px);
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar_container .sidebar_nav::-webkit-scrollbar {
  width: 0;
}

/* Track */
.sidebar_container .sidebar_nav::-webkit-scrollbar-track {
  background: #0000001a;
}

/* Handle */
.sidebar_container .sidebar_nav::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 50px;
}

/* Handle on hover */
.sidebar_container .sidebar_nav::-webkit-scrollbar-thumb:hover {
  background: #000000;
  border-radius: 50px;
}

/* .first_input {
  margin-right: -121px;
} */
/* .second_input {
  width: 30%;
  margin-left: 114px;
} */

.justco_secret_application_edit_form_row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}
.justco_secret_application_edit_form_row > * {
  width: 100%;
  padding: 0 12px;
}
.justco_secret_application_edit_form_row .first_input {
  max-width: 70%;
}
.justco_secret_application_edit_form_row .second_input {
  max-width: 30%;
}

.sidebar_container .sidebar_nav {
  list-style: none;
  margin: 30px -20px -15px;
  max-height: calc(100vh - 125px);
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar_container .sidebar_nav .sidebar_item .sidebar_link {
  padding: 12px 25px;
  font-size: 14px;
  font-weight: 500;
  color: #3f4358;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  border: none;
  height: 30px;
  color: #3f4358;
  background: #dddbdb;
  border-radius: 4px;
  padding: 7px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.sidebar_container .sidebar_nav .sidebar_item .sidebar_link::after {
  content: "";
  position: absolute;
  right: -1px;
  top: 50%;
  width: 0;
  height: 45px;
  border-radius: 60px 0 0 60px;
  background: #1e1e1e;
  transition: all 0.5s ease-in-out;
  transform: translateY(-50%);
}

.sidebar_container .sidebar_nav .sidebar_item .sidebar_link svg {
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  color: #1e1e1e;
  margin-right: 16px;
  transition: all 0.5s ease-in-out;
  background: #dddbdb;
  border-radius: 5px;
  padding: 7px;
}

.sidebar_container .sidebar_nav .sidebar_item .sidebar_link:hover,
.sidebar_container .sidebar_nav .sidebar_item .sidebar_link.active {
  color: #1e1e1e;
}

.sidebar_container .sidebar_nav .sidebar_item .sidebar_link:hover svg,
.sidebar_container .sidebar_nav .sidebar_item .sidebar_link.active svg {
  background: #1e1e1e;
  color: #ffffff;
}

.sidebar_container .sidebar_nav .sidebar_item .sidebar_link:hover::after,
.sidebar_container .sidebar_nav .sidebar_item .sidebar_link.active::after {
  width: 6px;
}

#sidebar_toggel:checked ~ .sidebar_container {
  max-width: 90px;
}

#sidebar_toggel:checked
  ~ .sidebar_container
  .sidebar_nav
  .sidebar_item
  .sidebar_link
  span,
#sidebar_toggel:checked
  ~ .sidebar_container
  .sidebar_nav
  .sidebar_item
  .sidebar_link::before {
  display: none;
}

#sidebar_toggel:checked
  ~ .sidebar_container
  .sidebar_nav
  .sidebar_item
  .sidebar_link
  svg {
  margin: 0 auto;
}

.sidebar_toggel_btn {
  cursor: pointer;
  color: #1e1e1e;
  min-width: 36px;
  max-width: 36px;
  height: 34px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sidebar_toggel_btn:hover {
  background: #dddbdb;
}

.sidebar_toggel_btn svg {
  width: 20px;
}

.sidebar_container .sidebar_logo .small_sidebar_logo,
#sidebar_toggel:checked ~ .sidebar_container .sidebar_logo .full_sidebar_logo {
  display: none;
}

#sidebar_toggel:checked ~ .sidebar_container .sidebar_logo .small_sidebar_logo {
  display: block;
}

.sidebar_container .sidebar_logo .small_sidebar_logo {
  width: 35px;
  height: 80px;
  margin: 0 auto;
}

/* #sidebar_toggel:checked  */

.event_inputs_column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 140px;
}

.err_margin_bottom {
  margin-bottom: 15px;
}

.type_form_content_devider {
  border-right: 2px solid #3f4358;
  padding-right: 15px;
}

/* sidebar css end */

/* top header css end */

.top_header_section {
  background: #ffffff;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  position: fixed;
  left: auto;
  top: 0;
  width: calc(100% - 280px);
  z-index: 99999;
  transition: all 0.5s ease-in-out;
}

#sidebar_toggel:checked ~ .main_content .top_header_section {
  width: calc(100% - 89px);
  left: auto;
}

#sidebar_toggel:checked
  ~ .main_content
  .project_edit_content
  .project_edit_navbar {
  max-width: calc(100% - 89px);
  left: auto;
}

.top_header_section .top_navbar_nav {
  list-style: none;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.top_navbar_nav > li {
  margin-left: 0.5rem;
}

.top_header_section .top_navbar_nav .top_navbar_item .top_navbar_logout_btn {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #1e1e1e;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: #dddbdb;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
}

.top_header_section
  .top_navbar_nav
  .top_navbar_item
  .top_navbar_logout_btn
  svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.top_header_section
  .top_navbar_nav
  .top_navbar_item
  .top_navbar_logout_btn:hover {
  color: #dddbdb;
  background: #1e1e1e;
}

.top_header_section .page_name_heading {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
  line-height: 21.6px;
  text-transform: capitalize;
}

/* top header css end */

/* login css start */

.login_section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: auto;
  padding: 0 15px;
  background-image: url("../images/WEB-2.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: top center;
}

.login_section::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #00000075;
}

.login_section .login_content {
  max-width: 451px;
  width: 100%;
  margin: 20px auto;
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  z-index: 999;
}

.m-w_login-content {
  max-width: 1000px !important;
}

.login_section .login_content .login_top_content {
  padding: 24px 24px 40px;
  background-image: url(../images/login-cover-image.jpg);
  background-size: cover;
  border-radius: 4px 4px 0 0;
}

.login_section .login_content .login_top_content h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #000000;
}

.login_section .login_content .login_top_content p {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
  color: #000000;
}

.login_section .login_content .login_input_logo_content {
  padding: 0 20px 20px;
}

.login_section .login_content .login_input_logo_content .login_logo {
  width: 72px;
  height: 72px;
  background: #1e1e1e;
  border-radius: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -26px;
}

.login_section .login_content .login_input_logo_content .login_logo img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.login_section .login_content .login_input_logo_content .login_input_content {
  padding: 32px 8px 8px;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row,
.login_input_row {
  margin-bottom: 16px;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row
  .login_otp_input
  input {
  width: 36px !important;
  height: 36px !important;
}

.create_from_row .login_input_row input::placeholder {
  color: #00000059 !important;
  letter-spacing: 2px;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row
  .login_otp_input
  > div,
.login_input_row .login_otp_input > div {
  margin: 0 -10px;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row
  .login_otp_input
  > div
  > div {
  margin: 0 10px;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row
  label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 13px;
  color: #000000;
  width: 100%;
}

.login_input_row .PhoneInput--disabled input {
  color: #cccccc;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row
  input,
.login_input_row input {
  display: block;
  width: 100%;
  padding: 7.5px 12px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #323941;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #5f6368;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  outline: 0;
}

.create_from_row .login_input_row input {
  border: 1px solid #ced4da;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_btn {
  width: 100%;
  color: #ffffff;
  background-color: #000000;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #000000;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_btn:hover {
  background-color: #ffffff;
  color: #000000;
}

/* login css end */

/* footer css start */

.footer_section p {
  font-size: 14px;
  font-weight: 500;
  color: #999;
}

.footer_section .footer_version_text {
  margin-left: auto;
}

/* footer css end */

/* project edit page css start */

.project_edit_main_content {
  padding: 20px;
  /* min-height: calc(100vh - 113px);
  height: auto; */
}

.project_edit_main_link {
  margin-bottom: 20px;
}

.amenities_back_btn {
  margin-left: auto;
}

.project_edit_main_content .project_edit_detail_column_content {
  background: #ffffff;
  /* box-shadow: 0 0 8px 2px #0000000d; */
  /* padding: 15px; */
  border-radius: 4px;
  width: 100%;
  max-width: 100%;
}

.project_edit_main_content .project_edit_detail_column_content h2,
.project_edit_main_content .custom_data_table_content h2,
.project_subheading {
  font-size: 22px;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.3;
  color: #59667a;
}

/* .project_edit_main_content .custom_data_table_content h2 {
  color: #59667a !important;
} */

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file,
.project_edit_main_content .project_edit_feature_input_file,
.project_edit_feature_input_file {
  border: 1px solid #ced4da;
  width: 101px;
  height: 101px;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file.six_nine_image,
.project_edit_main_content .project_edit_feature_input_file.six_nine_image,
.project_edit_feature_input_file.six_nine_image {
  width: 180px;
  height: 101px;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  input,
.project_edit_main_content .project_edit_feature_input_file input,
.project_edit_feature_input_file input {
  display: none;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  label,
.project_edit_main_content .project_edit_feature_input_file label,
.project_edit_feature_input_file label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dddbdbbd;
  transform: scale(0.8);
  transition: all 0.5s ease-in-out;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.create_from_label,
.login_input_row label {
  display: inline-block;
  color: #59667a;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.container_bottom_dashed_line {
  border-bottom: 1px rgba(0, 0, 0, 0.20784313725490197);
  margin-top: 5px;
  margin-bottom: 1px;
}

.country_code_or_label {
  display: inline-block;
  color: #59667a;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: -2px;
}

.notification_details_heading {
  color: #59667a;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  font-size: 14px;
  align-items: center;
}
.create_from_label .create_from_label_info_icon,
.login_input_row label .create_from_label_info_icon {
  margin-left: 5px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.create_from_label .create_from_label_info_icon svg,
.login_input_row label .create_from_label_info_icon svg {
  width: 18px;
  height: 18px;
  color: #1a73e8;
}

.create_from_label_btn_container {
  display: flex;
}

.create_link_btn_link {
  margin-bottom: 8px;
}

.feature_label {
  color: #59667a !important;
  font-weight: 500 !important;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  label
  span,
.project_edit_main_content .project_edit_feature_input_file label span,
.project_edit_feature_input_file label span {
  width: 35px;
  height: 35px;
  background: #ffffff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px 1px #00000035;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  label
  svg,
.project_edit_main_content .project_edit_feature_input_file label svg,
.project_edit_feature_input_file label svg {
  width: 15px;
  height: 15px;
  color: #3f4358;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file:hover
  label,
.project_edit_main_content .project_edit_feature_input_file:hover label,
.project_edit_feature_input_file:hover label {
  transform: scale(1);
  opacity: 1;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  img,
.project_edit_main_content .project_edit_feature_input_file img,
.project_edit_feature_input_file img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  .create_from_input_content,
.project_edit_main_content
  .project_edit_feature_input_file
  .create_from_input_content,
.project_edit_feature_input_file .create_from_input_content {
  height: 100%;
}

.token_button {
  margin-left: 9px;
  margin-top: 19px;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.create_from_input_content_code .create_from_input_code {
  background-color: #fff;
  font-size: 14px;
  border-color: #e6edef;
  color: #717171;
  display: block;
  width: 90%;
  padding: 6px 12px;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 4px;
  outline: 0;
  margin-top: 12px;
}

/* .project_edit_main_content .project_edit_detail_column_content h2 {
  font-size: 22px;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.3;
  color: #2c323f;
} */

.project_edit_main_content .project_edit_feature_input {
  margin-top: 16px;
}

.project_edit_main_content .project_edit_feature_input input[type="file"] {
  display: none;
}

.project_edit_main_content .project_edit_feature_input label {
  width: 100%;
  color: #59667a;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  font-size: 14px;
}

.project_edit_main_content .project_edit_feature_input input,
.project_edit_main_content .project_edit_feature_input textarea {
  background-color: #fff;
  font-size: 14px;
  border-color: #e6edef;
  color: #717171;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  outline: 0;
}

.image_remove_btn {
  background: #3f4358;
  color: #fff;
  border: 0;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 7px;
  top: 7px;
  box-shadow: -2px 3px 8px 2px #00000035;
  cursor: pointer;
  z-index: 99;
}

.image_remove_btn svg {
  width: 15px;
  height: 15px;
}

/* project edit page css end */

/* amenities page css start */

.creat_edit_project_btn_row {
  margin-bottom: 20px;
  text-align: right;
}

.creat_edit_project_btn_row_flex {
  display: flex;
}

.mt-20 {
  margin-top: 20px;
}

.rolemanagement_project_btn_row_flex {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.custom_data_table_content .custom_data_table {
  width: 100%;
  border-spacing: 0;
  border-radius: 4px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: #00000000 0px 0px 0px 0px, #00000000 0px 0px 0px 0px,
    #0000001a 0px 1px 3px 0px, #0000000f 0px 1px 2px 0px;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_head
  .custom_data_table_heading {
  background: #3f4358;
  font-weight: 700;
  color: #fff;
  font-size: 12px;
  padding: 10px;
  text-transform: uppercase;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row
  .custom_data_table_item {
  border-bottom: 1px solid #dddbdb;
  padding: 10px;
  font-size: 12px;
  color: #000;
  font-weight: 400;
}
.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row
  .custom_data_table_item
  a {
  font-size: 12px;
  /* color: #000; */
  font-weight: 400;
  /* text-decoration: none; */
}
.custom_data_link a {
  color: #000;
}
.table_item {
  text-align: center;
}

.mt-10 {
  margin-top: 20px !important;
}
.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row:last-child
  .custom_data_table_item {
  border-bottom: 0;
}

.custom_data_table_view_edit_btn_item_row {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  gap: 20px;
  margin: 0 auto;
}

.custom_data_table_view_edit_btn_item_row
  .custom_data_table_view_edit_item_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  border: none;
  height: 30px;
  color: #3f4358;
  background: #dddbdb;
  border-radius: 4px;
  padding: 7px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.hide_compoenent_btn {
  /* width: fit-content !important; */
  cursor: pointer;
}
.hide_compoenent_btn:disabled {
  pointer-events: none;
  background: #efefef;
}

.active_feature_btn {
  width: 80px !important;
}

.custom_data_table_view_edit_btn_item_row
  .custom_data_table_view_edit_item_btn:hover {
  background: #3f4358;
  color: #ffffff !important;
}

.custom_data_table_img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.custom_data_table_img_sidemenu {
  background-color: #3f4358;
}

.custom_data_table_content {
  overflow-x: auto;
  /* height: 100%; */
}
.custom_data_table_content table {
  min-width: 997px;
}

.custom_data_table_content .custom_data_table_width {
  min-width: fit-content;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row
  .custom_data_table_item
  .custom_data_table_button,
.custom_data_table_button {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #3f4358;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: #dddbdb;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border: 0;
  margin: 0 auto;
  cursor: pointer;
}

.sold_button {
  margin-left: 0.5rem;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row
  .custom_data_table_item
  .custom_data_table_button:hover,
.custom_data_table_button:hover {
  color: #ffffff;
  background: #3f4358;
}

/* amenities page css end */

/* Announcement page css start */

.announcement_page-container {
  display: flex;
  align-items: center;
}

.comman_btn_container.center_back_btn {
  padding: 20px 20px 0;
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
}
.comman_btn_container.center_back_btn .center_back_btn_row {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.comman_btn_container.center_back_btn .center_back_btn_row .comman_btn {
  margin-left: 20px;
}
.comman_btn_container.center_back_btn
  .center_back_btn_row
  .comman_btn:first-child {
  margin-left: 0px;
}

.comman_btn_container {
  padding: 20px 20px 0;
}

.mobile_configuration_heading {
  color: #3f4358;
  display: inline-block;
  padding: 0.5rem;
  /* margin-top: 1rem; */
  /* background: #3f4358; */
  border-radius: 0.2rem;
}

.center_back_btn .mobile_configuration_heading {
  padding: 0;
}
.edit-feature-headings {
  margin-bottom: 16px;
  padding: 0;
}

.edit-feature-column {
  margin-bottom: 20px;
}
.edit-feature-column:last-child {
  margin-bottom: 0px;
}

.disable_class {
  opacity: 0.7;
  pointer-events: none;
}

.user_edit_container {
  display: flex;
}

.create_from_row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 15px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: -3px 6px 13px 0.5px #0000000d;
  padding: 20px 25px;
  max-width: 1100px;
  margin: 0 auto;
}

.create_from_four_row {
  padding-top: 0 !important;
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.servicetype_form-container {
  max-width: 2000px;
}

.radio_update_service {
  box-shadow: none;
  padding: 0;
  gap: 0;
  display: block;
}
.radio_update_service_eidt_modal {
  margin-bottom: 10px;
}

.radio_update_service .radio_update_service_heading {
  color: #59667a;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.mb-5 {
  margin-bottom: 5px;
}

.create_from_nested_row {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 15px;
  background: #ffffff;
  /* border-radius: 4px; */
  /* box-shadow: -3px 6px 13px 0.5px #0000000d; */
  padding: 5px 25px 20px;
  max-width: 1100px;
  margin: 0 auto;
}
.create_from_nested_five_row {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 15px;
  background: #ffffff;
  /* border-radius: 4px; */
  /* box-shadow: -3px 6px 13px 0.5px #0000000d; */
  padding: 5px 25px 4px;
  max-width: 1100px;
  margin: 0 auto;
}

.create_from_input_content .create_from_label,
.login_input_row label {
  display: flex;
  color: #59667a;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  font-size: 14px;
}

.create_from_nested_label {
  display: block;
  background: #ffffff;
  color: #59667a;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.5px;
  padding-bottom: 8px;
  padding-left: 25px;
  font-size: 14px;
  max-width: 1100px;
  margin: 0 auto;
}

.create_from_nested_label_visitor {
  display: block;
  background: #ffffff;
  color: #59667a;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.5px;
  /* padding-bottom: 8px; */
  padding-left: 25px;
  font-size: 15px;
  max-width: 1100px;
  margin: 0 auto;
}

.create_from_input_content .create_from_input {
  background-color: #fff;
  font-size: 14px;
  border-color: #e6edef;
  color: #717171;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  outline: 0;
}

.dashboard_data_filter .select__single-value {
  color: #717171 !important;
}

.create_from_input_content .create_from_input::placeholder,
.css-1jqq78o-placeholder,
.css-14el2xx-placeholder,
.select__placeholder,
.project_edit_main_content .project_edit_feature_input input::placeholder,
.project_edit_main_content .project_edit_feature_input textarea::placeholder {
  color: #00000059 !important;
  letter-spacing: 2px;
}

.create_from_row
  .create_from_input_content
  .create_from_radio_checkbox_content {
  margin-bottom: 5px;
  /* margin-left: 5px; */
}

.create_from_row
  .create_from_input_content
  .create_from_radio_checkbox_content:last-child {
  margin-bottom: 0;
}

.disable_radio_btns {
  opacity: 0.5;
}

.create_from_radio_checkbox_contentainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 5px;
  margin-top: 4px;
}

.create_from_row .create_from_input_content .create_from_radio_label {
  position: relative;
  margin-left: 15px;
  /* font-weight: 500; */
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 21px;
  color: #010101;
  padding-left: 25px;
  cursor: pointer;
}

.create_from_radio_label_role {
  margin-left: 10px;
  /* font-weight: 500; */
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 21px;
  color: #717171;
  cursor: pointer;
}

.notification_sent {
  margin-left: 0px;
  /* font-weight: 500; */
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 21px;
  color: #717171;
  cursor: pointer;
}

.create_from_row .create_from_input_content .create_from_radio_label::before,
.create_from_radio_label::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  border: 1px solid #717171;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}

.create_from_row .create_from_input_content .create_from_radio_label::after,
.create_from_radio_label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 9px;
  height: 9px;
  left: 5px;
  top: 3px;
  border-radius: 50%;
  background-color: #59667a;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33),
    -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.create_from_row
  .create_from_input_content
  .create_from_radio_label.checked::after,
.create_from_radio_label.checked::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.input_none {
  display: none;
}

/* .create_from_row .create_from_input_content .create_from_radio_checkbox {
  display: none;
} */

.date-picker_input {
  padding: 2px 5px;
}
.country_modal_increased_section {
  max-width: 100% !important;
}

.inquiry_complaint_detail_section {
  padding: 20px;
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
}

.inquiry_complaint_detail_section .inquiry_complaint_detail_content {
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
}

.view-modal_container {
  grid-column: span 4 / span 4;
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 15px;
  gap: 15px;
}

.view_country_modal_container {
  display: flex;
  flex-wrap: wrap;
}

.view_country_modal_container .country_container {
  margin: 0 10px 10px 0;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body {
  padding: 20px;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_heading {
  font-size: 14px;
  font-weight: 500;
  color: #717171;
  margin-bottom: 4px;
  margin-left: 16px;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_text {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #00000035;
}

/* .inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_text:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
} */

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_name {
  font-size: 14px;
  font-weight: 400;
  color: #010101;
  text-transform: capitalize;
  padding-left: 16px;
}

.directory_link_validation {
  text-transform: none !important;
}

.contact_detail_link_container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.contact_detail_link_container:last-child {
  margin-bottom: 0;
}

.contact_link_name {
  margin-left: 0.4rem;
}

.announcement_page-view-modal {
  font-size: 25px;
  line-height: 1.3;
  color: #59667a;
  margin-bottom: 8px;
  text-align: center;
}

.country_code_modal_heading {
  font-size: 21px;
  line-height: 1.3;
  color: #3f4358;
  margin-bottom: 8px;
  text-align: center;
}

.delete_feedback_subheading {
  font-size: 17px;
}

.d-grid_features {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 15px;
  gap: 15px;
  padding-top: 15px;
}

.delete_feedback_btn-container {
  display: flex;
  justify-content: center;
}
.edit_view_service_btn-container {
  display: flex;
  /* justify-content: center; */
}

.user_data_search
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input[placeholder] {
  color: #717171 !important;
  letter-spacing: 0px !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.user_data_search_select_box_row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.user_data_search_select_box_row .user_data_search {
  position: relative;
  max-width: 230px;
  width: 100%;
  margin-right: 16px;
}

.user_data_search input {
  padding: 6px 12px;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 4px;
  color: #717171;
}

.user_data_search_select_box_row .user_data_search:last-child {
  margin-right: 0;
}

.user_data_search_select_box_row .user_data_search .user_data_search_input {
  padding-right: 35px;
}

.user_data_search .user_data_search_input,
.basic-multi-select .select__control {
  background-color: #fff;
  font-size: 14px;
  border-color: #e6edef;
  color: #717171;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  outline: 0;
}

.select__control--is-disabled {
  color: #ababab !important;
  cursor: not-allowed !important;
  border: 1px solid #eeeeee !important;
}
.project_edit_main_content .visitor_label_tenant.select--is-disabled {
  cursor: not-allowed;
  pointer-events: all;
}
.project_edit_main_content
  .visitor_label_tenant.select--is-disabled
  .select__single-value {
  color: #ababab !important;
}

.user_data_search .select__placeholder {
  color: #717171 !important;
  letter-spacing: 0px !important;
}

.user_data_search_select_box_row .user_data_search .user_data_search_btn {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: 0;
  height: 100%;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user_data_search_select_box_row .user_data_search .user_data_search_btn svg {
  height: 17px;
  width: 17px;
  color: #cccccc;
}

.user_data_search_select_box_row
  .user_data_search
  .user_data_search_btn:hover
  svg {
  color: #000;
}

.user_data_search_select_box_row
  .user_data_search
  .user_data_search_btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 17px;
  background: #2524241a;
}

.project_edit_feature_add_remove_btn_row {
  display: flex;
  align-items: center;
  margin: 16px 0;
  justify-content: space-between;
}

.project_edit_feature_add_remove_btn_row .project_edit_feature_add_remove_btn {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: #3f4358;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border: 0;
  cursor: pointer;
}

.project_edit_feature_add_remove_btn_row
  .project_edit_feature_add_remove_btn.project_edit_feature_add_btn {
  margin-left: auto;
}

.project_edit_feature_add_remove_btn_row
  .project_edit_feature_add_remove_btn
  svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.project_edit_feature_add_remove_btn_row
  .project_edit_feature_add_remove_btn:hover {
  color: #3f4358;
  background: #2524241a;
}

.about_page_section {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  max-width: 1100px;
  margin: 0 auto;
}

.about_page_section .about_detaile_text_content {
  margin-bottom: 20px;
}

.about_page_section .about_detaile_text_content:last-child {
  margin-bottom: 0;
}

.about_page_section .about_detaile_text_content h2,
.about_page_section .about_detaile_text_content h1 {
  font-size: 20px;
  color: #3f4358;
  margin-bottom: 4px;
}

.about_page_section .about_detaile_text_content h1 {
  font-size: 30px;
}

.about_page_section .about_detaile_text_content p {
  font-size: 14px;
  color: #000000;
  margin-bottom: 0;
}

.about_page_section .about_detaile_text_content a {
  color: #3f4358;
}

.user_detail_component_container {
  max-width: 1100px;
  margin: 0 auto 20px;
}

.about_detaile_row {
  gap: 15px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
}

.feature_display_name {
  display: block;
}

.create_link_btn {
  background: none;
  border: none;
  color: #717171;
  border-bottom: 1px solid #717171;
  margin-left: 0.5rem;
  cursor: pointer;
}

.directory_dropdown {
  z-index: 99999;
}

.create_link_btn:hover {
  opacity: 0.8;
  border: none;
}

.btn_container {
  display: flex;
  align-items: center;
}

.create_btn_notification {
  margin-bottom: 8px;
}

.btn_container label {
  width: auto !important;
}

.custom_announcement_img {
  width: 90%;
  height: 90%;
}

.create_from_radio_label_role_enable_checkbox {
  margin-left: 10px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 21px;
  color: #59667a;
  cursor: pointer;
}

/* Announcement page css start */

/* Settings page css start */

.zu_loginas-btn {
  margin-left: 3px;
  cursor: pointer;
  border: 0;
  padding: 10px 20px;
  color: #000000;
  background: #00000008;
  border-radius: 12px;
  text-decoration: none;
  font-size: 15px;
}

.zu_loginas-btn[disabled] {
  background: #000000;
  color: #ffffff;
  pointer-events: none;
}

.settings_container {
  margin-top: 1rem;
}

.disabled_btn {
  color: grey;
  pointer-events: none;
}
.disabled_btn_visitor_page {
  color: grey;
  /* cursor: not-allowed !important; */
  /* pointer-events: none !important; */
}

.disabled_btn_visitor_page:hover {
  color: grey;
  cursor: not-allowed !important;
  background: #3f4358;
  border: 1px solid #3f4358;
}

.zu_common_form_input_label {
  font-size: 22px;
  margin-bottom: 5px;
  color: #3f4358;
}

.zu_common_form_textarea_content .card {
  border-radius: 0;
  border: 1px solid #e2e2e2;
  margin-top: 0.5rem;
}

.zu_common_form_textarea_content .card .rdw-editor-toolbar {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #e2e2e2;
}

.zu_common_form_textarea_content
  .card
  .rdw-editor-toolbar
  .rdw-dropdown-wrapper,
.zu_common_form_textarea_content .card .rdw-editor-toolbar .rdw-option-wrapper {
  box-shadow: none !important;
  border: 1px solid #e2e2e2;
}

.language-switcher {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 34px;
}

.language-switcher input {
  opacity: 0;
  width: 0;
  height: 0;
}

.select-fr,
.select-en {
  position: absolute;
  font-size: 17px;
  top: 7px;
  color: #fff;
  mix-blend-mode: difference;
}
.select-fr {
  left: 7px;
}
.select-en {
  right: 7px;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  border: 1px solid black;
  box-shadow: 0 3px 64px rgba(#1e3470, 0.1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 32px;
  width: 32px;
  left: 0;
  bottom: 0;
  background-color: #000000;
  box-shadow: 0 3px 64px rgba(#1e3470, 0.16);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ffffff;
}

input:focus + .slider {
  box-shadow: none;
}

input:checked + .slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.language_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.setting_btns_container {
  max-width: 1100px;
  margin: 0 auto;
}

.setting_token_input {
  min-width: 25rem;
}
.create_from_input_content.setting_token_input {
  min-width: 100%;
}

.basic-multi-select .__control {
  color: #00000059 !important;
  letter-spacing: 2px;
  font-weight: 400;
  line-height: 1.5;
  font-size: 14px;
}
.basic-multi-select .__single-value {
  letter-spacing: 0;
}

.modal_input_container {
  margin-top: 12px !important;
}

/* .user_data_search .user_data_search_input,
.basic-multi-select .select__control {
  background-color: #fff;
  font-size: 14px;
  border-color: #e6edef;
  color: #717171;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  outline: 0;
}

.basic-multi-select .select__control .select__value-container,
.basic-multi-select
  .select__control
  .select__value-container
  .select__single-value,
.basic-multi-select
  .select__control
  .select__value-container
  .select__input-container,
.basic-multi-select
  .select__control
  .select__value-container
  .select__input-container
  input {
  padding: 0;
  margin: 0;
  color: #717171;
}

.basic-multi-select .select__control .select__indicators {
  margin: -6px -6px -6px 0;
}

.basic-multi-select .select__control .select__indicators .select__indicator {
  padding: 6px;
}

.basic-multi-select .select__control {
  display: flex;
  align-items: center;
  box-shadow: none;
  min-height: auto;
}

.user_data_search_select_box_row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.user_data_search_select_box_row .user_data_search {
  position: relative;
  max-width: 230px;
  width: 100%;
  margin-right: 16px;
}

.user_data_search_select_box_row .user_data_search .user_data_search_input {
  padding-right: 35px;
}

.user_data_search_select_box_row .user_data_search .user_data_search_btn {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: 0;
  height: 100%;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user_data_search_select_box_row .user_data_search .user_data_search_btn svg {
  height: 17px;
  width: 17px;
  color: #cccccc;
}

.user_data_search_select_box_row
  .user_data_search
  .user_data_search_btn:hover
  svg {
  color: #000;
}

.user_data_search_select_box_row
  .user_data_search
  .user_data_search_btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 17px;
  background: #cccccc;
}

.user_data_search_select_box_row .user_data_select {
  max-width: 220px;
  width: 100%;
}

.lead_status-options {
  margin-left: 1rem;
} */

/* user list page css end */

/* create page css start */

/* .create_from_row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 15px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: -3px 6px 13px 0.5px #0000000d;
  padding: 20px 25px;
  max-width: 1100px;
  margin: 0 auto;
}

.tower_dropdown {
  z-index: 99;
}

.plan_dropdown {
  z-index: 9;
}

.create_from_input_content .create_from_label {
  display: inline-block; */
/* color: #59667a;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  font-size: 14px;
}

.create_from_input_content .create_from_input {
  background-color: #fff;
  font-size: 14px;
  border-color: #e6edef;
  color: #717171;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  outline: 0;
}

.create_from_input_content .create_from_input::placeholder,
.css-14el2xx-placeholder,
.project_edit_main_content .project_edit_feature_input input::placeholder,
.project_edit_main_content .project_edit_feature_input textarea::placeholder {
  color: #00000059 !important;
  letter-spacing: 2px;
}

.create_from_row .create_from_input_content .create_from_radio_checkbox {
  display: none;
}

.create_from_row .create_from_input_content .create_from_radio_label,
.create_from_radio_label {
  position: relative;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 21px;
  color: #242934;
  padding-left: 25px;
  cursor: pointer;
}

.create_from_row .create_from_input_content .create_from_radio_label::before,
.create_from_radio_label::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  border: 1px solid #c11f3b;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}

.create_from_row .create_from_input_content .create_from_radio_label::after,
.create_from_radio_label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 9px;
  height: 9px;
  left: 5px;
  top: 3px;
  border-radius: 50%;
  background-color: #c11f3b;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33),
    -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.create_from_row
  .create_from_input_content
  .create_from_radio_label.checked::after,
.create_from_radio_label.checked::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.create_from_row
  .create_from_input_content
  .create_from_radio_checkbox_content {
  margin-bottom: 5px;
}

.create_from_row
  .create_from_input_content
  .create_from_radio_checkbox_content:last-child {
  margin-bottom: 0;
}

.create_from_row .create_from_input_content input[type="file"] {
  display: none;
}

.create_from_row .create_from_input_content .create_from_file_btn {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #c11f3b;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border: 1px solid #c11f3b;
  cursor: pointer;
  width: 100%;
  max-width: 150px;
}

.create_from_row .create_from_input_content .create_from_file_btn svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.create_from_row .create_from_input_content .create_from_input_file {
  border: 1px solid #000000;
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
}

.create_from_row .create_from_input_content .create_from_input_file label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c11f3b35;
  transform: scale(0.8);
  transition: all 0.5s ease-in-out;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
}

.create_from_row
  .create_from_input_content
  .create_from_input_file:hover
  label {
  transform: scale(1);
  opacity: 1;
}

.create_from_row .create_from_input_content .create_from_input_file label span {
  width: 35px;
  height: 35px;
  background: #ffffff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px 1px #00000035;
}

.create_from_row
  .create_from_input_content
  .create_from_input_file
  label
  span
  svg {
  width: 15px;
  height: 15px;
  color: #c11f3b;
}

.create_from_row .create_from_input_content .create_from_input_file img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.create_from_row .create_from_input_content .create_from_input_file img ~ label,
.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  img
  ~ label,
.create_from_input_file_specification img ~ label {
  display: none;
}

.create_from_row .create_from_input_content .create_from_plan_document_input {
  margin-top: 16px;
}

.create_from_row .create_from_input_content .create_from_extra_added_content {
  margin-bottom: 16px;
}

.create_from_row
  .create_from_input_content
  .create_from_extra_added_content:last-child {
  margin-bottom: 0;
}

.customer_properties_number_input .create_from_input {
  max-width: 50%;
}

.customer_properties_number_input .create_from_bottom_btn {
  margin-top: 16px;
}

.customer_properties_number_extra_added_content {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 15px;
}

.project_edit_detail_column_content .create_from_input_content {
  margin-bottom: 15px;
}

.project_edit_detail_column_content .create_from_input_content:last-child {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}

.project_detail-name {
  margin-left: 1rem;
  text-transform: uppercase;
  font-size: 14px;
  padding: 2px 7px;
  font-weight: 500;
  background: #c11f3b;
  border: 1px solid #c11f3b;
  color: #fff;
  vertical-align: middle;
  border-radius: 4px;
} */

/* create page css end */

/* error filed css start */

/* .error_filed .create_from_label, */
/* .error_filed .create_from_input::placeholder,
.error_filed .select__placeholder {
  color: #f00;
}

.error_filed .create_from_input,
.error_filed .basic-multi-select .select__control,
.error_filed
  .basic-multi-select
  .select__control
  .select__indicators
  .select__indicator,
.error_filed .create_from_input_file {
  color: #f00;
  border-color: #f00;
}

.error_filed .css-1okebmr-indicatorSeparator {
  background-color: #f00;
}*/

.error_filed .error_filed_text,
.err_text {
  color: #f00;
  font-size: 13px;
  display: inline-block;
  margin-top: 5px;
}

.just_co_err_text {
  color: #f00;
  font-size: 13px;
  margin-top: 5px;
}

.error_text {
  color: #c11f3b;
  font-size: 14px;
  display: inline-block;
}

/* .mobile-icon {
  padding: 6px 10px;
  font-weight: 600;
  font-size: 14px;
  color: #c11f3b;
  background-color: #c11f3b1a;
  border-radius: 4px;
} */

/* error filed css end */

/* project list card css start */

/* .project_list_card  {
  background: #ffffff;
  border-radius: 5px;
  padding: 18px 18px 24px;
}

.project_list_card .project_list_card_img {
  width: 100%;
  box-shadow: 0px 20px 40px -20px rgba(0, 0, 0, 0.3),
    0px 30px 70px -40px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  min-height: 240px;
  max-height: 241px;
  object-fit: cover;
}

.project_list_card .project_list_card_body {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 275px);
}

.project_list_card .project_list_card_body .project_list_card_detaile {
  margin-bottom: 14px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.project_list_card .project_list_card_body h2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #c11f3b;
  margin-bottom: 9px;
}

.project_list_card .project_list_card_body p {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.007em;
  color: #240006;
  font-weight: 400;
}

.project_list_card .project_list_card_body .project_list_card_bottom_btn_row {
  border-top: 2px solid #fbf1f3;
  margin-top: 14px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  margin-top: auto;
}

.project_edit_link {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn,
.project_list_card_view_edit_btn {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #c11f3b;
  text-decoration: none;
  max-width: 165px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn
  svg,
.project_list_card_view_edit_btn svg {
  width: 25px;
  height: 25px;
  color: #c11f3b;
  background: #c11f3b1a;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  transition: all 0.5s ease-in-out;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn:hover
  svg,
.project_list_card_view_edit_btn:hover svg {
  background: #c11f3b;
  color: #ffffff;
}

.project_list_card_view_edit_btn.link_yellow svg {
  background: #ffb8001a;
  color: #ffb800;
}

.project_list_card_view_edit_btn.link_yellow:hover svg {
  background: #ffb800;
  color: #ffffff;
}

.link_yellow {
  color: #ffb800;
}

.link_red {
  color: #c11f3b;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn:last-child {
  margin: 0 auto;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn
  svg {
  width: 25px;
  height: 25px;
  color: #c11f3b;
  background: #c11f3b1a;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  transition: all 0.5s ease-in-out;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn:hover
  svg {
  background: #c11f3b;
  color: #ffffff;
} */

/* project list card css end */

/* lead page css start */

/* .referrals_label_row {
  display: flex;
  align-items: center;
  list-style: none;
  flex-wrap: wrap;
  gap: 15px;
}

.referrals_label_row .referrals_label_item,
.referrals_label_row .color_label_item {
  padding-left: 18px;
  position: relative;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.007em;
  color: #240006bf;
}

.referrals_label_row .referrals_label_item::before,
.referrals_label_row .color_label_item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background: #c11f3b;
  border-radius: 50px;
}

.referrals_label_row .referrals_label_item.submitted::before,
.lead_list_column .lead_list_label_time_text_row.submitted::before {
  background: #79dee8 !important;
}

.referrals_label_row .referrals_label_item.confirmed::before,
.lead_list_column .lead_list_label_time_text_row.confirmed::before {
  background: #824de1 !important;
}

.referrals_label_row .referrals_label_item.visited::before,
.lead_list_column .lead_list_label_time_text_row.visited::before {
  background: #e3c15f !important;
}

.referrals_label_row .referrals_label_item.converted::before,
.lead_list_column .lead_list_label_time_text_row.converted::before {
  background: #c1e865 !important;
}

.referrals_label_row .pending::before,
.lead_list_column .lead_list_label_time_text_row.pending::before {
  background: #e10000 !important;
}

.referrals_label_row .resolved::before,
.lead_list_column .lead_list_label_time_text_row.resolved::before {
  background: #ffb800 !important;
}

.referrals_label_row .closed::before,
.lead_list_column .lead_list_label_time_text_row.closed::before {
  background: #1dce00 !important;
}

.lead_list_row {
  margin: 16px;
}

.lead_list_column {
  padding: 18px;
  background: #ffffff;
  border-radius: 5px;
}

.lead_list_column .lead_list_label_time_text_row {
  display: flex;
  align-items: center;
  padding: 5px 0;
  position: relative;
  margin-bottom: 12px;
  padding-left: 13px;
}

.lead_list_column .lead_list_label_time_text_row .lead_list_label_text {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.007em;
  color: #240006bf;
  text-transform: capitalize;
}

.lead_list_column .lead_list_label_time_text_row::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 100%;
  background: #c11f3b;
  border-radius: 10px;
}

.lead_list_column .lead_list_label_time_text_row .lead_list_time_text {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.007em;
  color: #240006bf;
  margin-left: auto;
}

.lead_list_column .lead_list_name_other_detail .lead_list_proparty_name {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.007em;
  color: #240006bf;
  font-weight: 400;
  margin-bottom: 12px;
}

.lead_list_column .lead_list_name_other_detail .lead_list_user_name {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.007em;
  text-transform: uppercase;
  color: #240006;
  margin-bottom: 8px;
}

.lead_list_column .lead_list_name_other_detail .lead_list_user_contact_text {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.007em;
  color: #240006bf;
}

.lead_list_column
  .lead_list_name_other_detail
  .lead_list_collapse_btn_contact_text {
  display: flex;
  align-items: center;
}

.lead_list_column .lead_list_name_other_detail .lead_list_collapse_btn {
  margin-left: auto;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  transition: all 0.5s ease-in-out;
  background: #c11f3b1a;
  border-radius: 5px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.lead_list_column .lead_list_name_other_detail .lead_list_collapse_btn:hover {
  background: #c11f3b;
}

.lead_list_column
  .lead_list_name_other_detail
  .lead_list_collapse_btn.active
  svg {
  transform: rotate(0);
}

.lead_list_column .lead_list_name_other_detail .lead_list_collapse_btn svg {
  transform: rotate(180deg);
}

.lead_list_column
  .lead_list_name_other_detail
  .lead_list_collapse_btn
  svg
  path {
  transition: all 0.5s ease-in-out;
}

.lead_list_column
  .lead_list_name_other_detail
  .lead_list_collapse_btn:hover
  svg
  path {
  stroke: #ffffff;
}

.lead_list_column .lead_list_name_other_detail .lead_list_collapse_body {
  padding-top: 12px;
  margin-top: 8px;
  border-top: 1px solid #24000614;
  display: none;
}

.lead_list_column .lead_list_name_other_detail .lead_list_collapse_body p {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.007em;
  color: #24000680;
}

.lead_list_column .lead_list_name_other_detail .lead_list_collapse_body.show {
  display: block;
}

.lead_project {
  z-index: 99;
}

.lead_referred {
  z-index: 9;
}

.lead_table-status {
  padding: 7px 12px;
  background-color: rgba(49, 49, 49, 0.1);
}

.lead_confirmed {
  color: rgb(155, 155, 5) !important;
}
.lead_submitted {
  color: rgb(5, 5, 146) !important;
}
.lead_rejected {
  color: #c11f3b !important;
}
.lead_visited {
  color: rgb(2, 78, 2) !important;
}
.lead_converted {
  color: rgb(3, 128, 128) !important;
} */

/* lead page css end */

/* media query css end */

/* .companydetail_image {
  display: flex;
  width: 50%;
}

.edit_view_icon {
  display: flex;
  align-items: space-around;
} */

/* .back_btn {
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding: 3px 10px;
} */

/*

.remove_pdf_btn {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #c11f3b;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: #c11f3b1a;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border: 0;
  cursor: pointer;
  margin-top: 10px;
  margin-left: auto;
}

.remove_pdf_btn:hover {
  color: #ffffff;
  background: #c11f3b;
}

.remove_pdf_btn svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.create_from_input_file_specification {
  border: 1px solid #000000;
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  color: #f00;
}

.create_from_input_file_specification label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c11f3b35;
  transform: scale(0.8);
  transition: all 0.5s ease-in-out;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.create_from_input_file_specification:hover label {
  transform: scale(1);
  opacity: 1;
}

.create_from_input_file_specification label span {
  width: 35px;
  height: 35px;
  background: #ffffff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px 1px #00000035;
}

.create_from_input_file_specification label span svg {
  width: 15px;
  height: 15px;
  color: #c11f3b;
}

.create_from_input_file_specification img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.specification_image {
  display: flex;
}

.lead_date {
  display: flex;
}

.lead_date > * {
  padding: 0.5rem 0.1rem;
  color: grey;
  margin: 0 0 0.5rem 0;
  border-radius: 0.2rem;
}

.lead-col_item {
  width: 30%;
  max-width: 50rem;
}

.plan_list-image {
  width: 100%;
}

.text-center {
  text-align: center;
}

.tech_font {
  font-size: 1.25rem !important;
}

.tech_docs {
  display: flex;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.table {
  margin-top: 3rem;
}

.table-head {
  background-color: #ccc;
}

.table-heading {
  padding: 0.35rem 0;
}

.table-body {
  padding: 0.5rem 0;
}

.table-body-row {
  background-color: #eee;
}

.table-body-row:hover {
  background-color: #ddd;
}

.table-body-link {
  text-decoration: none;
} */

/* about page css start */

/* .about_page_section {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  max-width: 1100px;
  margin: 0 auto;
}

.about_page_edit_create_btn {
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
}

.about_page_section .about_page_slider {
  padding-bottom: 50px;
  border-bottom: 2px solid #fbf1f3;
  margin: 38px auto 20px;
} */

/* .about_page_section .about_page_slider .slick-slider {
  box-shadow: 0px 20px 40px -20px rgb(0 0 0 / 30%),
    0px 30px 70px -40px rgb(0 0 0 / 60%);
} */

/* .about_page_section .about_page_slider .slick-slider,
.about_page_section .about_page_slider .slick-slider .slick-slide,
.about_page_section .about_page_slider .slick-slider .slick-slide img {
  max-height: 350px;
  min-height: 350px;
  max-width: 765px;
  margin: 0 auto;
}

.about_page_section .about_page_slider .slick-slider .slick-slide img {
  width: 100%;
  object-fit: cover;
}

.about_page_section .about_page_slider .slick-slider .slick-prev,
.about_page_section .about_page_slider .slick-slider .slick-next {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background: #c11f3b1a;
  z-index: 999;
  transition: all 0.5s ease-in-out;
  opacity: 1;
  top: -25px;
}

.about_page_section .about_page_slider .slick-slider .slick-prev:hover,
.about_page_section .about_page_slider .slick-slider .slick-next:hover {
  opacity: 1;
  background: #c11f3b;
}

.about_page_section .about_page_slider .slick-slider .slick-prev::before {
  content: "";
  border: solid #c11f3b;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: translate(-50%, -50%) rotate(135deg);
  -webkit-transform: translate(-50%, -50%) rotate(135deg);
  left: 58%;
  top: 50%;
  position: absolute;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.about_page_section .about_page_slider .slick-slider .slick-next::before {
  content: "";
  border: solid #c11f3b;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: translate(-50%, -50%) rotate(-45deg);
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  left: 44%;
  top: 50%;
  position: absolute;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.about_page_section .about_page_slider .slick-slider .slick-prev:hover::before,
.about_page_section .about_page_slider .slick-slider .slick-next:hover::before {
  border-color: #ffffff;
}

.about_page_section .about_page_slider .slick-slider .slick-prev {
  left: auto;
  right: 30px;
}

.about_page_section .about_page_slider .slick-slider .slick-list {
  border-radius: 10px;
}

.about_page_section .about_page_slider .slick-slider .slick-next {
  right: 0;
}

.about_page_section .about_page_slider .slick-slider .slick-slide {
  cursor: grab;
}

.about_page_section .about_page_slider .slick-slider .slick-slide:active {
  cursor: grabbing;
}

.about_page_section .about_detaile_text_content {
  margin-bottom: 20px;
}

.about_page_section .about_detaile_text_content:last-child {
  margin-bottom: 0;
}

.about_page_section .about_detaile_text_content h2,
.about_page_section .about_detaile_text_content h1 {
  font-size: 20px;
  color: #c11f3b;
  margin-bottom: 4px;
}

.about_page_section .about_detaile_text_content h1 {
  font-size: 30px;
}

.about_page_section .about_detaile_text_content p {
  font-size: 14px;
  color: #000000;
  margin-bottom: 0;
}

.about_page_section .about_detaile_text_content a {
  color: #c11f3b;
}

.follow_me-link {
  text-decoration: none;
  border-bottom: 1px solid #c11f3b;
  color: #c11f3b;
  font-size: 13px;
}

.about_detaile_row {
  gap: 15px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
}
.spinner {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffff;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company_video_preview {
  width: 100%;
  height: 100%;
}

.submit-company-btn {
  margin-top: 1.5rem;
} */

/* about page css end */

/* .featured_project_heading {
  color: #c11f3b;
  margin: 1rem 0;
}

.mobile_configuration_heading {
  color: #c11f3b; */
/* display: inline-block;
  padding: 0.5rem;
  margin-top: 1rem;
  background: #e7c3c985;
  border-radius: 0.2rem; */
/* } */

/* data table css start */

/* .custom_data_table_content .custom_data_table {
  width: 100%;
  border-spacing: 0;
  border-radius: 4px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: #00000000 0px 0px 0px 0px, #00000000 0px 0px 0px 0px,
    #0000001a 0px 1px 3px 0px, #0000000f 0px 1px 2px 0px;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_head
  .custom_data_table_heading {
  background: #c11f3b;
  font-weight: 700;
  color: #fff;
  font-size: 12px;
  padding: 10px;
  text-transform: uppercase;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row
  .custom_data_table_item {
  border-bottom: 1px solid #c1203b1f;
  padding: 10px;
  font-size: 12px;
  color: #000;
  font-weight: 400;
}
.table_item {
  text-align: center;
}
.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row:last-child
  .custom_data_table_item {
  border-bottom: 0;
}

.custom_data_table_view_edit_btn_item_row {
  display: flex;
  align-items: center; */
/* justify-content: center; */
/* max-width: 70px;
  width: 100%;
  gap: 20px;
  margin: 0 auto;
} */

/* .custom_data_table_view_edit_btn_item_row
  .custom_data_table_view_edit_item_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  border: none;
  height: 30px;
  color: #c11f3b;
  background: #c11f3b1a;
  border-radius: 4px;
  padding: 7px;
  transition: all 0.5s ease-in-out;
}

.custom_data_table_view_edit_btn_item_row
  .custom_data_table_view_edit_item_btn:hover {
  background: #c11f3b;
  color: #ffffff;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row
  .custom_data_table_item
  .custom_data_table_button,
.custom_data_table_button {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #c11f3b;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: #c11f3b1a;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border: 0;
  margin: 0 auto;
  cursor: pointer;
}

.sold_button {
  margin-left: 0.5rem;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row
  .custom_data_table_item
  .custom_data_table_button:hover,
.custom_data_table_button:hover {
  color: #ffffff;
  background: #c11f3b;
} */

/* data table css end */

/* plan page css start */

/* .plan_list_column {
  background: #ffffff;
  border-radius: 5px;
  padding: 18px 18px 24px;
  position: relative;
  box-shadow: 0 2px 7px #dfdfdf;
}

.plan_list_column .plan_list_image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: 240px;
  max-height: 240px;
}

.plan_list_column .plan_list_img {
  position: relative;
  border-radius: 5px;
  width: 100%;
  min-height: 240px;
  max-height: 240px;
  overflow: hidden;
}

.plan_list_column .plan_list_img .plan_list_add_update_time {
  background: #0000006e;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px;
  box-shadow: 0px -2px 10px 1px #00000035;
  width: 100%;
  display: flex;
  align-items: center;
}

.plan_list_column .plan_list_img .plan_list_add_update_time p {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}

.plan_list_column .plan_list_img .plan_list_add_update_time p span {
  font-size: 12px;
  color: #fff;
  display: block;
  margin-top: 3px;
}

.plan_list_column
  .plan_list_img
  .plan_list_add_update_time
  .plan_list_update_time {
  margin-left: auto;
  text-align: right;
}

.plan_list_column .plan_list_body {
  padding: 18px 0 0;
  display: flex;
  flex-direction: column;
  min-height: 140px;
  height: auto;
}
.plan_list_column .plan_list_body .plan_list_title {
  display: block;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ccc;
  margin-bottom: 10px;
}

.plan_list_column .plan_list_body .plan_list_name {
  font-weight: 500;
  display: block;
  margin-bottom: 18px;
  text-transform: uppercase;
  color: #c11f3b;
  text-decoration: none;
  transition: 0.3s;
}

.plan_list_column .plan_list_body .plan_list_bottom_btn {
  border-top: 2px solid #fbf1f3; */
/* margin-top: 14px; */
/* padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: auto;
} */

/* .plan_list_column .plan_list_body .plan_list_bottom_btn .plan_list_bottom {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #c11f3b;
  text-decoration: none;
  max-width: 165px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}

.plan_list_column .plan_list_body .plan_list_bottom_btn .plan_list_bottom svg {
  width: 25px;
  height: 25px;
  color: #c11f3b;
  background: #c11f3b1a;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  transition: all 0.5s ease-in-out;
}

.plan_list_column
  .plan_list_body
  .plan_list_bottom_btn
  .plan_list_bottom:hover
  svg {
  background: #c11f3b;
  color: #ffffff;
}

.plan_slider_box {
  position: relative;
  max-height: 350px;
  min-height: 350px;
}

.plan_slider_box .plan_slider_box_text_content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #0000008f;
  padding: 15px;
  box-shadow: 0px -3px 11px 3px #00000059;
}

.plan_slider_box .plan_slider_box_text_content .plan_slider_heading {
  font-size: 24px;
  color: #fff;
}

.plan_slider_box
  .plan_slider_box_text_content
  .plan_slider_heading
  ~ .plan_slider_peragraph {
  margin-top: 4px;
}
.plan_slider_box .plan_slider_box_text_content .plan_slider_peragraph {
  font-size: 16px;
  color: #fff;
}

.plan_page_section .about_page_slider {
  padding-bottom: 30px;
}

.plan_slider_box .plan_slider_box_text_content .plan_slider_category {
  font-size: 13px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 4px;
}

.plan_photo_view {
  position: absolute;
  top: 5px;
  right: 10px;
  color: #ffffff;
  font-size: 25px;
  border: 1px solid white;
  padding: 2px 10px;
  border-radius: 4px;
}

.plan_photo_view:hover {
  opacity: 0.7;
} */

/* plan page css end */

/* customer properties page css start */

.customer_properties_column {
  background: #ffffff;
  border-radius: 5px;
  padding: 18px 18px 24px;
  display: flex;
  flex-direction: column;
}

.customer_properties_column .customer_properties_user_type_name {
  border-radius: 5px;
  padding: 10px;
  border-left: 5px solid #c11f3b;
}

.debited .customer_properties_user_type_name,
.customer_properties_user_type_name {
  background: #c11f3b1a;
}

.credited .customer_properties_user_type_name {
  background: #039c291a;
}
.customer_properties_column
  .customer_properties_user_type_name
  .customer_properties_user_type {
  font-size: 11px;
  color: #c11f3b;
  font-weight: 600;
  display: block;
  margin-bottom: 3px;
}

.customer_properties_column
  .customer_properties_user_type_name
  .customer_properties_user_name {
  font-size: 16px;
  color: #c11f3b;
  font-weight: 600;
  display: block;
}
/* 
.credited .customer_properties_user_type_name {
  border-left: 5px solid green;
}

.debited .customer_properties_user_name,
.customer_properties_user_name {
  color: #c11f3b;
}

.credited .customer_properties_user_name {
  color: green;
}

.customer_properties_column .customer_properties_other_details {
  margin-top: 10px;
  border-bottom: 1px solid #c1203b1f;
  padding-bottom: 10px;
}

.customer_properties_column
  .customer_properties_other_details
  .customer_properties_other_details_label {
  font-size: 13px;
  color: #c11f3b;
  font-weight: 400;
  display: block;
  margin-bottom: 1px;
  font-weight: 500;
}

.customer_properties_column
  .customer_properties_other_details
  .customer_properties_other_details_text {
  font-size: 17px;
  font-weight: 700;
  margin-top: 10px;
  color: #000000b5;
  display: block;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  line-height: 1.2;
}

.customer_properties_column
  .customer_properties_discription_details
  .customer_properties_other_details_text {
  height: 85px;
}

.customer_properties_column.CUSTOMER .customer_properties_user_type_name {
  background: #ffb8001a;
  border-color: #ffb800;
}

.customer_properties_column.CUSTOMER
  .customer_properties_user_type_name
  .customer_properties_user_type,
.customer_properties_column.CUSTOMER
  .customer_properties_user_type_name
  .customer_properties_user_name,
.customer_properties_column.CUSTOMER
  .customer_properties_other_details
  .customer_properties_other_details_label {
  color: #ffb800;
}

.customer_properties_column.CUSTOMER .customer_properties_other_details {
  border-color: #ffb8001f;
}

.customer_properties_column .project_list_card_bottom_btn_row {
  margin-top: auto;
}

.customer_properties_column .customer_properties_other_details:nth-child(4) {
  margin-bottom: 5px;
}

.property-next-btn {
  margin-top: 1rem;
}

input[type="checkbox" i] {
  accent-color: #c11f3b;
}

.notice_column {
  display: flex;
  flex-direction: column;
}

.notice_create_edit_date_row {
  display: flex;
  align-items: center;
}

.notice_create_edit_date_row .notice_edit_date_text {
  margin-left: auto;
  text-align: right;
}

.notice_create_edit_date_row .notice_date_heading {
  font-size: 16px;
  color: #c11f3b;
  font-weight: 600;
  display: block;
  margin-bottom: 3px;
}

.notice_create_edit_date_row .notice_date_time_text {
  font-size: 11px;
  color: #c11f3b;
  font-weight: 600;
  display: block;
}

.notice_subject_discription_text {
  margin-top: 20px;
  margin-bottom: 10px;
}

.notice_subject_discription_text .notice_subject_text {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #00000035;
}

.notice_subject_discription_text .notice_discription_text {
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1.2px;
  color: #000000;
}

.notice_column .project_list_card_bottom_btn_row {
  margin-top: auto;
}
.lead_btn_container {
  display: flex;
  justify-content: center;
}
.notice_column
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn {
  justify-content: flex-start;
}

.verify_btn {
  margin-top: 1rem;
}

.project_edit_feature_input ~ .project_edit_media_input_file {
  margin-top: 16px;
}

.project_edit_detail_divider {
  border-bottom: 1px solid #000000;
  padding-bottom: 20px;
  margin-bottom: 16px;
}

.project_edit_detail_divider .project_edit_detail_divider {
  border-bottom: 1px solid #00000038;
}

.project_edit_detail_divider:last-child,
.project_edit_detail_divider .project_edit_detail_divider:last-child {
  border-bottom: 0;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.create_from_row .project_edit_detail_column_content {
  box-shadow: none;
  border-bottom: 2px dashed #00000047;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.create_from_row .project_edit_detail_column_content:last-child {
  border-bottom: 0;
}

.single_column_body .create_from_row,
.amenities_body .create_from_row {
  gap: 0;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.document_body .project_edit_feature_media_row {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.center_table_body {
  max-width: 1140px;
  margin: 0 auto;
}
.csr_data_component {
  margin-top: 1.5rem;
}

.lead_page_body .user_data_search_select_box_row .user_data_search {
  margin-right: 0;
}

.inquiry_complaint_detail_section {
  padding: 20px;
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
}

.inquiry_complaint_detail_section .inquiry_complaint_detail_content {
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_video_img {
  position: relative;
  height: 300px;
  overflow: hidden;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_video_img
  .default_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_video_img
  .inquiry_complaint_detail_status {
  position: absolute;
  right: 10px;
  top: 10px;
  background: #f9e8eb;
  color: #c11f3b;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 3px;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_video_img
  iframe {
  border: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px 6px 0 0;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body {
  padding: 20px;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_create_updated_date {
  padding: 20px;
  margin: -20px -20px 20px;
  background: #c11f3b1a;
  display: flex;
  align-items: center;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_create_updated_date
  h2 {
  color: #c11f3b;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_create_updated_date
  .inquiry_complaint_detail_updated_date {
  margin-left: auto;
  text-align: right;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_create_updated_date
  p {
  color: #c11f3b;
  font-size: 15px;
  font-weight: 600;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_name {
  font-size: 17px;
  font-weight: 400;
  color: #010101;
  text-transform: capitalize;
  padding-left: 16px;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_text {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #00000035;
}

.inquiry_complaint_detail_section
  .inquiry_complaint_detail_content
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_text:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
} */

/* customer properties page css end */

/* dashboard page css start */

.dashboard_body {
  padding: 20px;
}

.dashboard_top_column_row {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 30px;
  margin-bottom: 1.5rem;
}

.flex_center {
  justify-content: center;
}

.flex_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.download_btn {
  margin-top: 1rem;
  padding: 4px 20px;
}

.dashboard_top_column_row .dashboard_top_column {
  box-shadow: 0 5px 20px #9299b808;
  border-radius: 10px;
  background: #fff;
  padding: 25px;
  display: flex;
  align-items: flex-start;
}

.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_left_content
  .dashboard_top_column_left_count_text {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
  color: #272b41;
}
.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_left_content
  .comman_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_left_content
  .comman_btn
  .oc-btn-loader {
  margin-left: 10px;
}

.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_left_content
  .dashboard_top_column_left_peragraph_text {
  margin-top: 2px;
  color: #272b41;
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 600;
  text-transform: capitalize;
}

.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_left_content
  .dashboard_top_column_left_bottom_text {
  color: #868eae;
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_left_content
  .dashboard_top_column_left_bottom_text
  span {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: #20c997;
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_left_content
  .dashboard_top_column_left_bottom_text
  span
  svg {
  margin-right: 2px;
}

.dashboard_top_column_row
  .dashboard_top_column.down_grad
  .dashboard_top_column_left_content
  .dashboard_top_column_left_bottom_text
  span {
  color: #ff4d4f;
}

.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_main_icon {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dddbdb;
}

.dashboard_project-link {
  text-decoration: none;
}

.user_profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user_detail_modal_bg {
  background: #272d4e;
  transition: all 0.5s ease-in-out;
  position: fixed;
  top: 0;
  left: -100%;
  /* z-index: -9; */
  z-index: 999999;
  width: 100%;
  height: 100%;
  /* opacity: 0; */
  /* display: none; */
  opacity: 0.58;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.user_detail_modal_bg.user_detail_modal_show {
  /* opacity: 0.58; */
  /* z-index: 999999; */
  /* display: block; */
  left: 50%;
}

.user_detail_modal_main {
  background: linear-gradient(0deg, #fff, #fff);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: all 0.5s ease-in-out;
  position: fixed;
  left: -100%;
  top: 50px;
  /* z-index: -9; */
  z-index: 999999;
  max-height: calc(100vh - 100px);
  width: 100%;
  max-width: 1116px;
  overflow-y: auto;
  border: 1px solid #8a98a8;
  padding: 18px 25px;
  /* opacity: 0; */
  /* display: none; */
}

.country_width {
  /* max-width: 1516px !important; */
  max-width: calc(100vw - 100px) !important;
}

.delete_feedback_modal {
  width: auto;
}

.add_modal_container {
  width: 350px;
}

.user_detail_modal_main.user_detail_modal_show {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
}

.user_property {
  display: flex;
  justify-content: space-between;
}

.user_referred_btn {
  padding: 2px 13px;
}

.create_notice_heading {
  text-align: center;
  margin-bottom: 1rem;
}

.create_notice_buttons {
  display: flex;
  justify-content: center;
}
.create_notice_buttons > * {
  margin: 0 1rem;
}

.create_project_review {
  font-size: 12px;
  line-height: 15px;
  margin: 10px 0 8px 0;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: #59667a;
  display: inline-block;
}

.rera_number {
  width: 100%;
}
.user_detail_modal_main.user_detail_modal_show {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
}

.user_property {
  display: flex;
  justify-content: space-between;
}

.user_referred_btn {
  padding: 2px 13px;
}

.create_notice_heading {
  text-align: center;
  margin-bottom: 1rem;
}

.create_notice_buttons {
  display: flex;
  justify-content: center;
}
.create_notice_buttons > * {
  margin: 0 1rem;
}

.create_project_review {
  font-size: 12px;
  line-height: 15px;
  margin: 10px 0 8px 0;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: #59667a;
  display: inline-block;
}

.rera_number {
  width: 100%;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  margin: 27px -5px 0;
}

.pagination .pagination__link--disabled {
  pointer-events: none;
}

.pagination li {
  padding: 5px;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3f4358;
  color: #3f4358;
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff;
  background: #3f4358;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

.user_lead_heading {
  font-size: 25px;
  color: #c11f3b;
  margin-bottom: 6px;
  text-decoration: underline;
}

.user_detail_lead {
  margin-bottom: 1.5rem;
}

.user_lead-project {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}
.user_lead-status {
  font-size: 13px;
}

.user_detail-property {
  display: flex;
  justify-content: space-between;
}

.user_detail-nodata {
  text-align: center;
  color: #c11f3b;
}

.user_reward_header {
  display: flex;
  justify-content: space-between;
}

.user-detail_navbar {
  position: relative;
}

.user_wallet_status {
  position: absolute;
  right: 25px;
  display: flex;
  align-items: center;
}

.user_wallet_heading {
  margin-right: 1rem;
}

.user_detail-walletbalance {
  font-size: 15px;
  padding: 3px 13px;
  font-weight: 600;
  background: #c11f3b;
  border: 1px solid #c11f3b;
  color: #fff;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: inline-block;
}

.user_detail-balance {
  margin-left: 10px;
  background: white;
  color: #c11f3b;
  padding: 3px 5px;
  border-radius: 5px;
}

.tower_dropdown {
  z-index: 99999;
}

.create_lead_referred {
  display: flex;
  width: 50%;
  justify-content: space-between;
}

.complaint_view_modalbtn {
  margin-top: 1rem;
  margin-left: 9px;
}

.ml-20 {
  margin-left: 20px;
}

.lead_status_rejected_confirmed {
  display: flex;
  justify-content: space-between;
  width: 70%;
}

.notice_header {
  justify-content: space-between;
}

.notice_user-type {
  font-size: 13px;
  font-weight: bold;
  color: #c11f3b;
}

.comman_center_btn_container {
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
}

/* #chart{
  width: 130px !important;
  height: 93px !important;
} */

.center-container {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
}

/* dashboard page css end */

/* table image modal css start */
.oc-table-img-modal-bg {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  transform: scale(0.75);
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  background: #00000035;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}
.oc-table-img-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 999999;
  transform: translate(-50%, -50%) scale(0.75);
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.oc-table-img-modal img {
  max-width: 100%;
  height: auto;
}
.oc-table-img-modal-bg.oc-table-img-modal-open {
  transform: scale(1);
  pointer-events: all;
  opacity: 1;
}
.oc-table-img-modal.oc-table-img-modal-open {
  transform: translate(-50%, -50%) scale(1);
  pointer-events: all;
  opacity: 1;
}
/* table image modal css end */

.custom_table_three_btn_row {
  justify-content: flex-start;
  max-width: 130px;
  margin: 0 auto;
}

.custom_table_four_btn_row {
  justify-content: flex-start;
  max-width: 228px;
  margin: 0 auto;
}

.create_role_heaing_radio_row {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 15px;
  grid-column: span 2 / span 2;
  align-items: center;
}
.create_role_heaing_radio_row .create_from_radio_label_role {
  grid-column: span 4 / span 4;
}
.create_role_heaing_radio_row .create_role_heaing_radio_row_input {
  grid-column: span 4 / span 4;
  display: grid;
  grid-template-columns: repeat(auto-fill, 235px);
  /* grid-template-columns: repeat(4, minmax(0, 1fr)); */
  gap: 15px;
  padding-top: 15px;
  border-top: 1px solid #ced4da;
}

.table_two_btn_row {
  max-width: 80px;
  width: 100%;
  justify-content: flex-start;
}

.oc_feedback_detail_modal {
  max-width: 991px;
}
.oc_feedback_detail_modal .inquiry_complaint_detail_section {
  padding: 0;
}
.oc_feedback_detail_modal
  .inquiry_complaint_detail_section
  .announcement_page-view-modal {
  margin-bottom: 24px;
}
.oc_feedback_detail_modal
  .inquiry_complaint_detail_section
  .inquiry_complaint_detail_body {
  padding: 0;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 24px;
}
.oc_feedback_detail_modal
  .inquiry_complaint_detail_section
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_text
  .inquiry_complaint_detail_heading {
  font-size: 14px;
  font-weight: 600;
  color: #717171;
  margin-bottom: 4px;
}
.oc_feedback_detail_modal
  .inquiry_complaint_detail_section
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_text {
  margin: 0;
}
.oc_feedback_detail_modal
  .inquiry_complaint_detail_section
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_text.contact_modal_time {
  border: 0;
  padding-bottom: 0;
}
.oc_feedback_detail_modal
  .inquiry_complaint_detail_section
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_text.contact_modal_time
  > div {
  border-bottom: 1px dashed #00000035;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.oc_feedback_detail_modal
  .inquiry_complaint_detail_section
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_text.contact_modal_time
  > div:last-child {
  margin-bottom: 0px;
}
.oc_feedback_detail_modal
  .inquiry_complaint_detail_section
  .inquiry_complaint_detail_body
  .inquiry_complaint_detail_text
  .inquiry_complaint_detail_name {
  font-size: 12px;
  font-weight: 400;
  color: #010101;
  margin-bottom: 0px;
  padding-left: 0;
  margin-left: 16px;
}

.view_page_img_icon_view {
  cursor: pointer;
}

.oc_service_table_btn_row .custom_data_table_view_edit_btn_item_row {
  max-width: 80px;
  justify-content: flex-start;
}
.oc_directory_detail_modal {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.oc_directory_detail_modal .oc_directory_detail_modal_3_col {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 15px;
  grid-column: span 2 / span 2;
}

.visitor_infom_form_col {
  grid-column: span 2 / span 2;
}
.visitor_infom_form_col .visitor_infom_form_heading {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.3;
  color: #59667a;
}

.oc-thankyou-modal-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: auto;
  padding: 15px;
}

.oc-thankyou-modal {
  max-width: 451px;
  width: 100%;
  margin: 20px auto;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  overflow: hidden;
}

.oc-thankyou-modal .oc-thankyou-modal-content .oc-thankyou-modal-banner-img {
  background: #ffffff;
}

.oc-thankyou-modal
  .oc-thankyou-modal-content
  .oc-thankyou-modal-banner-img
  img {
  width: 60%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.oc-thankyou-modal .oc-thankyou-modal-content .oc-thankyou-modal-text {
  padding: 30px 20px;
}
.oc-thankyou-modal .oc-thankyou-modal-content .oc-thankyou-modal-text h2 {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 16px;
  color: #000000;
}
.oc-thankyou-modal .oc-thankyou-modal-content .oc-thankyou-modal-text p {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  color: #000000;
}

.basic-multi-select .select__control {
  position: relative;
  z-index: 9;
}
.tenant_floor_selectBox .basic-multi-select {
  z-index: 9999 !important;
}
.basic-multi-select.purpose_selectBox {
  z-index: 99 !important;
}

.basic-multi-select.z-999999 {
  z-index: 999999 !important;
}
.basic-multi-select.z-99999 {
  z-index: 99999 !important;
}
.basic-multi-select.z-9999 {
  z-index: 9999 !important;
}

.visitor_disabled_input.PhoneInput--disabled {
  color: #ababab !important;
  cursor: not-allowed;
  border: 0 !important;
}
.visitor_disabled_input.PhoneInput--disabled input {
  color: #ababab !important;
  cursor: not-allowed;
  border: 1px solid #eeeeee !important;
}
.select__control--is-disabled,
.__control--is-disabled {
  background: transparent !important;
  pointer-events: none;
}

.basic-multi-select.select--is-disabled,
.basic-multi-select.--is-disabled {
  position: relative;
  cursor: not-allowed !important;
  pointer-events: all;
}

/* .row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.row > * {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.col-3 {
  max-width: 25%;
  width: 100%;
}
.col-4 {
  max-width: 33.333333%;
  width: 100%;
}
.col-5 {
  max-width: 41.666666%;
  width: 100%;
} */

.oc_change_status_modal_box_row {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 15px;
}

.token_regenerate_input_box .create_from_input_content.setting_token_input {
  min-width: auto;
  width: 100%;
  margin-right: 15px;
}

/* Country Code */
.country_container {
  border: 2px solid #3f4358;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  background: #dddbdb;
  color: #000;
  width: max-content;
}

.close_btn_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.event_date_picker_wrapper,
.event_img_wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 15px;
  gap: 15px;
}
.event_date_picker_wrapper .create_from_label {
  display: flex;
  color: #59667a;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  font-size: 14px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px) contrast(0.9) brightness(0.9);
  z-index: 1000;
}

.form_modal_content {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  position: relative;
  width: 550px;
  text-align: center;
  overflow-y: auto;
  max-height: 600px;
}

.form_modal_inner_content {
  /* background-color: #dddbdb; */
  padding: 20px;
  border-radius: 8px;
  /* border: 1px dashed #dddbdb; */
}

/* Additional styles for controlling image size within the container */
.form_modal_inner_content img {
  max-width: 100%; /* Prevent images from stretching beyond their natural width */
  height: auto; /* Maintain the aspect ratio */
  margin-top: 20px;
}

.read-more-link {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 10px;
}

.modal-content {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  position: relative;
  width: 500px;
  text-align: center;
}
.modal-footer {
  text-align: center;
  margin-top: 20px;
}

.close-button {
  font-size: 14px;
  padding: 6px 28px;
  font-weight: 600;
  background: #3f4358;
  border: 1px solid #3f4358;
  color: #dddbdb;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.35s ease-in-out, background-color 0.35s ease-in-out,
    border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out;
  display: inline-block;
  margin-top: 5px;
}

.close-button:hover {
  background-color: #dddbdb;
  border-color: #dddbdb;
  color: #1e1e1e;
}

.modal-overlay.close {
  display: none;
}

.visitor_floor_id {
  margin-top: -3px;
}

/* media query css start */

@media (max-width: 1700px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 125px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column {
    max-height: 130px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    min-height: 162px;
  }
}

@media (max-width: 1600px) {
  .project_edit_main_content .col_lg_4 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .project_edit_main_content .col_lg_3 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 1500px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 95px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column {
    max-height: 100px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    min-height: 132px;
  }
}

@media (max-width: 1400px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    min-height: 132px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .dashboard_top_column_row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 1300px) {
  .download_btn {
    padding: 4px 12px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 125px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .project_edit_main_content .col_lg_4,
  .lead_list_row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .project_edit_main_content .col_lg_3 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (max-width: 1200px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 95px;
  }

  .document_body .project_edit_feature_media_row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 1175px) {
  .dashboard_top_column_row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (max-width: 1100px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 125px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 991px) {
  .sidebar_container {
    max-width: 90px;
  }
  #sidebar_toggel:checked ~ .sidebar_container {
    max-width: 280px;
  }
  .sidebar_container .sidebar_nav .sidebar_item .sidebar_link span,
  .sidebar_container .sidebar_nav .sidebar_item .sidebar_link::before {
    display: none;
  }
  #sidebar_toggel:checked
    ~ .sidebar_container
    .sidebar_nav
    .sidebar_item
    .sidebar_link
    span,
  #sidebar_toggel:checked
    ~ .sidebar_container
    .sidebar_nav
    .sidebar_item
    .sidebar_link::before {
    display: block;
  }
  .sidebar_container .sidebar_nav .sidebar_item .sidebar_link svg {
    margin: 0 auto;
  }
  #sidebar_toggel:checked
    ~ .sidebar_container
    .sidebar_nav
    .sidebar_item
    .sidebar_link
    svg {
    margin-right: 16px;
    margin-left: 0;
  }
  .sidebar_container .sidebar_logo .small_sidebar_logo,
  #sidebar_toggel:checked
    ~ .sidebar_container
    .sidebar_logo
    .full_sidebar_logo {
    display: block;
  }
  .sidebar_container .sidebar_logo .full_sidebar_logo,
  #sidebar_toggel:checked
    ~ .sidebar_container
    .sidebar_logo
    .small_sidebar_logo {
    display: none;
  }
  .main_content {
    max-width: calc(100% - 90px);
  }
  #sidebar_toggel:checked ~ .main_content {
    max-width: calc(100% - 280px);
  }
  .top_header_section {
    width: calc(100% - 89px);
  }
  .project_edit_content .project_edit_navbar {
    max-width: calc(100% - 89px);
  }
  #sidebar_toggel:checked ~ .main_content .top_header_section {
    width: calc(100% - 280px);
  }
  #sidebar_toggel:checked
    ~ .main_content
    .project_edit_content
    .project_edit_navbar {
    max-width: calc(100% - 280px);
  }
  .project_edit_main_content .project_edit_feature_media_row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 175px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column {
    max-height: 175px;
  }
}

@media (max-width: 767px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 125px;
  }
  .project_edit_main_content .col_lg_4,
  .lead_list_row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .top_header_section
    .top_navbar_nav
    .top_navbar_item
    .top_navbar_logout_btn
    span {
    display: none;
  }
  .top_header_section
    .top_navbar_nav
    .top_navbar_item
    .top_navbar_logout_btn
    svg {
    margin-right: 0;
  }
  .top_header_section .top_navbar_nav .top_navbar_item .top_navbar_logout_btn {
    padding: 6px 8px;
  }
  .footer_section {
    flex-direction: column;
    text-align: center;
    padding: 15px 10px;
  }
  .footer_section .footer_version_text {
    margin: 15px auto 0;
  }
  .oc_directory_detail_modal {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .language_container {
    flex-direction: column;
  }
  .token_btn {
    margin-top: 10px;
  }
  .create_from_nested_row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .pagination {
    flex-wrap: wrap;
    justify-content: center;
  }
  .pagination .previous,
  .pagination .next {
    width: 100%;
  }

  .justco_secret_application_edit_form_row .first_input,
  .justco_secret_application_edit_form_row .second_input {
    max-width: 100%;
  }
  .event_img_wrapper {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (max-width: 600px) {
  .oc_mobile_version_cont {
    display: block;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .customer_properties_column
    .customer_properties_user_type_name
    .customer_properties_user_type {
    font-size: 10px;
  }
  .customer_properties_column
    .customer_properties_user_type_name
    .customer_properties_user_name {
    font-size: 13px;
  }
  .customer_properties_column {
    padding: 15px 15px 10px;
  }
  .setting_token_input {
    min-width: 194px;
  }
  .user_data_search_select_box_row {
    flex-wrap: wrap;
    flex-basis: 150px;
  }
  .user_data_search_select_box_row > div {
    margin-bottom: 10px;
    margin-right: 0 !important;
  }
  .create_from_nested_row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .add_modal_container {
    width: 300px;
  }
}

@media (max-width: 500px) {
  /* .project_edit_main_content {
    padding: 0;
  } */

  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .top_header_section {
    padding: 15px 10px;
    width: calc(100% - 70px);
  }
  .sidebar_container {
    max-width: 70px;
    padding: 15px 10px;
  }
  .main_content {
    max-width: calc(100% - 70px);
  }
  .project_list_card .project_list_card_img {
    min-height: 160px;
    max-height: 160px;
  }
  .sidebar_container .sidebar_nav {
    margin: 30px -10.5px -15px;
  }
  .sidebar_container .sidebar_nav .sidebar_item .sidebar_link {
    padding: 12px;
  }
  .about_page_section .about_page_slider .slick-slider,
  .about_page_section .about_page_slider .slick-slider .slick-slide,
  .about_page_section .about_page_slider .slick-slider .slick-slide img {
    max-height: 320px;
    min-height: 320px;
    max-width: 320px;
  }
  .plan_slider_box {
    max-height: 320px;
    min-height: 320px;
  }
  .language_container {
    flex-wrap: wrap;
  }
  .dashboard_top_column_row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .login_section .create_from_row,
  .create_from_row {
    display: block;
  }
  .login_section .create_from_row .create_from_input_content,
  .create_from_row .create_from_input_content,
  .event_date_picker_wrapper {
    margin: 7px 0;
  }
  .visitor_infom_form_heading {
    margin-top: 15px;
  }
  .oc_directory_detail_modal {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .token_container {
    width: fit-content;
  }
  .comman_btn_container {
    flex-direction: column;
  }
  .comman_btn {
    margin: 10px 0;
  }
  .create_from_nested_row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .view-modal_container {
    display: block;
  }
  .inactive_btn {
    margin: 0;
  }
  .create_role_heaing_radio_row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .oc_directory_detail_modal .oc_directory_detail_modal_3_col {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .about_detaile_row {
    display: block;
  }

  .delete_feedback_btn-container .user_detail_modal_close_btn {
    margin-left: 5px !important;
  }
}

/* buttons in row */
.visitor-button-row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: -2.5px -2.5px 10px;
}

.visitor-button {
  font-size: 12px;
  padding: 6px 18px;
  background: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 2.5px;
}

.visitor-button:hover {
  background: #def;
  cursor: pointer;
}

.visitor-button.is-active {
  background: #3f4358;
  border: 1px solid #3f4358;
  color: white;
}

.disabled-visitor-button {
  background: #fdfdfd;
  color: #adadad;
  cursor: not-allowed !important;
}
.disabled-visitor-button:hover {
  background: #fdfdfd;
  color: #adadad;
  cursor: not-allowed !important;
}

.service_request_description {
  max-height: 15rem;
  overflow-y: auto;
  word-wrap: break-word;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #eee;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaa;
  }
}
